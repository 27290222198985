<template>
  <div class="payment-table-responsive" v-loading="loadingPaymentHub">
    <div class="row-collapse">
      <div class="row-collapse__container accordion" role="tablist">
        <div
          v-for="(item, index) in lineItemsToShow"
          :key="index"
          class="row-collapse__container--row"
          v-b-toggle="`collapse-information${index}`"
        >
          <div class="card" role="tab">
            <div class="card__check">
              <div class="card__information--item">
                <el-checkbox
                  v-model="item.is_checked"
                  :disabled="selectedOption === 'past-due'"
                  @change="(e) => handleCheck(e, item)"
                />
              </div>
            </div>
            <div class="card__information">
              <div class="card__information--item">
                <div class="item-label">LOAD NUMBER</div>
                <div class="item-value">{{ item.load_number }}</div>
              </div>
              <div class="card__information--item">
                <div class="item-label">BILLING REFERENCE</div>
                <div class="item-value">{{ item.billing_reference }}</div>
              </div>
              <div class="card__information--item">
                <div class="item-label">BILLING NUMBER</div>
                <div class="item-value">{{ item.name }}</div>
              </div>
            </div>
            <div class="card__values">
              <div class="card__information">
                <div class="card__information--item">
                  <div class="item-label">TOTAL</div>
                  <div class="item-value">$ {{ item.total }}</div>
                </div>
                <div class="card__information--item">
                  <div class="item-label">BALANCE</div>
                  <div class="item-value">$ {{ item.balance }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PaymentHubTableResponsive",
  data() {
    return {
      selectAll: false,
      selectedOption: this.initialCheckboxOption,
    };
  },
  props: {
    lineItemsToShow: {
      type: Array,
      default() {
        return [];
      },
    },
    loadingPaymentHub: {
      type: Boolean,
      default: false,
    },
    disabledCheckboxes: {
      type: Boolean,
      default: false,
    },
    initialCheckboxOption: {
      type: String,
      default: "selected-invoices",
    },
  },

  components: {},
  watch: {
    initialCheckboxOption(val) {
      this.selectedOption = val;
    },
    selectAll(newVal) {
      this.lineItemsToShow.forEach((item) => {
        item.is_checked = newVal;
      });
    },
  },
  methods: {
    toggleSelectAll() {
      this.$emit("checkAll", true);
    },

    handleCheck(e, item) {
      this.$emit("handleCheck", { check: e, item });
    },
  },
};
</script>
<style lang="scss" scoped>
.payment-table-responsive {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.row-collapse {
  position: relative;
  &__container {
    height: 72vh;
    padding: 2px;
    overflow: auto;
    @extend %scroll-bar-styles;
    &::-webkit-scrollbar {
      display: none;
    }
    &--row {
      @include collapse-container-styles;
    }
    @media (max-width: 800px) {
      height: 57vh;
    }
  }
}

::v-deep .row-collapse__container--row {
  cursor: default;
}

.card {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: none;
  justify-content: space-around;
  align-items: flex-start;
  cursor: default;
  &__check {
    width: 10%;
  }
  &__information {
    width: 75%;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 2px;
    gap: 2px;
    &--item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      font-size: 13px;
      .item-label {
        text-align: left;
        width: 50%;
      }
      .item-value {
        text-align: left;
        width: 50%;
      }
    }
  }
  &__values {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: $text-primary;
    font-size: 13px;
  }
  &__actions {
    width: auto;
    padding: 0px 5px;
  }
}

.item-label {
  color: $color-primary-company;
  font-weight: bold;
}
.item-value {
  color: $text-primary;
}
</style>
