<template>
  <div class="payment-hub-container" ref="agGridTableContainer" v-loading="loadingPaymentHub">
    <div class="payment-hub-table" v-if="screenWidth > 800">
      <!-- Search Section -->
      <div class="search-container">
        <el-input
          placeholder="Search..."
          suffix-icon="ion ion-search-outline"
          v-model="search"
          class="input-search"
          clearable
        />
      </div>
      <!-- End Search Section -->
      <div class="payment-table-container">
        <el-table :data="filteredItems" :height="tableHeight" :max-height="maxHeight">
          <el-table-column width="50" id="checkbox-cell-container">
            <template slot="header">
              <label class="custom-checkbox">
                <input
                  ref="checkboxAll"
                  type="checkbox"
                  v-model="selectAll"
                  @change="handleCheckAll(true)"
                />
                <span class="custom-checkbox-box"></span>
              </label>
            </template>
            <template slot-scope="scope">
              <div class="check-container">
                <el-checkbox
                  v-model="scope.row.is_checked"
                  @change="(e) => handleCheck(e, scope.row)"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(column, index) in columnDefs"
            :key="index"
            :label="column.label"
            :prop="column.field"
            :sortable="column.sortable"
            :width="column.width"
          >
            <!-- Apply currency formatting to the “total” and “balance” columns -->
            <template v-slot="scope">
              <span v-if="column.field === 'total' || column.field === 'balance'">
                $ {{ scope.row[column.field] | insurancePrice }}
              </span>
              <span v-else>{{ scope.row[column.field] }}</span>
            </template>
            <!-- End Formatter Section -->
          </el-table-column>
          <el-table-column :width="25" align="center">
            <template slot-scope="scope">
              <button class="show-load-button" @click="goToLoadInfo(scope.row)">
                <img
                  src="@/assets/scss/icons/view-details.svg"
                  class="show-load-icon"
                  alt="filter-icon"
                />
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div div class="payment-hub-responsive" v-else>
      <PaymentHubTableResponsive
        :lineItemsToShow="lineItemsToShow"
        :loadingPaymentHub="loadingPaymentHub"
        :disabledCheckboxes="disabledCheckboxes"
        :defaultOption="selectedOption"
        @handleCheck="(obj) => handleCheck(obj.check, obj.item)"
        @checkAll="(e) => handleCheckAll()"
      ></PaymentHubTableResponsive>
    </div>
    <el-divider class="custom-divider" direction="vertical"></el-divider>
    <div class="payment-hub">
      <PaymentHub
        :defaultOption="selectedOption"
        :pastDueTotal="pastDueTotal"
        :totalBalance="totalBalance"
        :totalSelectedRows="totalSelectedRows"
        :loadingPayment="loadingPayment"
        @paymentHubOption="paymentHubOption"
        @goPayment="goPayment"
      ></PaymentHub>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import "@/assets/scss/components/agGridStyleOverride.scss";
import PaymentHub from "./PaymentHub.vue";
import PaymentHubTableResponsive from "./PaymentHubTableResponsive.vue";
import onReSize from "../../../Extend/onResize";

export default {
  name: "paymentHubTable",
  components: { PaymentHub, PaymentHubTableResponsive },
  mixins: [onReSize],
  data() {
    return {
      rowData: null,
      tableHeight: "600px",
      maxHeight: "600px",
      columnDefs: [
        {
          label: "LOAD NUMBER",
          field: "load_number",
        },
        {
          label: "BILLING REFERENCE",
          field: "billing_reference",
        },
        {
          label: "BILLING NUMBER",
          field: "name",
        },
        {
          label: "DUE DATE",
          field: "due_date",
          sortable: true,
          sortMethod: this.sortDates,
        },
        {
          label: "TOTAL",
          field: "total",
        },
        {
          label: "BALANCE",
          field: "balance",
          sortable: true,
          sortMethod: this.sortNumbers,
          loadingPaymentHub: false,
        },
      ],
      selectedRows: [],
      search: "",
      pastDueArray: [],
      skip: 0,
      limit: 200,
      lineItemsToShow: [],
      lineItemsToShowCopy: [],
      loadingPaymentHub: false,
      disabledCheckboxes: false,
      /* Total Values */
      pastDueTotal: 0,
      totalBalance: 0,
      totalSelectedRows: 0,
      selectedOption: "selected-invoices",
      selectAll: false,
    };
  },
  computed: {
    filteredItems() {
      return this.filterItems(this.lineItemsToShow, this.search);
    },
    /* Then you may want to disable the checkboxes. */
    /* disabledCheckbox() {
      return this.selectedOption === "past-due";
    }, */
    ...mapGetters({
      newLoadPaymentData: "paymentHub/newLoadPaymentData",
      loadingPayment: "paymentHub/loadingPayment",
    }),
  },
  created() {
    this.getTableData();
  },
  /* It is necessary to reset the vuex value, so that if we switch between tabs, it is not the behavior of retrying payment. */
  beforeDestroy() {
    this.$store.commit("paymentHub/setBillings", []);
  },
  methods: {
    async getTableData() {
      this.loadingPaymentHub = true;
      const payload = {
        skip: this.skip,
        limit: this.limit,
      };
      const response = await this.$store.dispatch("paymentHub/getAllBillingsToPay", payload);
      this.lineItemsToShow = response;
      if (this.newLoadPaymentData.length > 0) {
        const idsFromLoadId = this.newLoadPaymentData.map((item) => item.id);
        const foundItems = this.lineItemsToShow.filter((item) => idsFromLoadId.includes(item.id));
        if (foundItems.length > 0) {
          foundItems.forEach((foundItem) => {
            const matchingIndex = this.lineItemsToShow.findIndex(
              (listItem) => listItem.id === foundItem.id
            );
            if (matchingIndex !== -1) {
              // Update the matching element in `lineItemsToShow`
              this.lineItemsToShow.splice(matchingIndex, 1, {
                ...foundItem, // Preserve existing properties
                is_checked: true, // Set "is_checked" to true
              });
              this.updateSelectedValue();
            }
          });
        } else {
          this.lineItemsToShow.unshift({
            ...this.newLoadPaymentData[0],
            is_checked: true,
          });
          this.updateSelectedValue();
        }
      }
      this.lineItemsToShowCopy = this.lineItemsToShow;
      /* Assign initial values payment hub */
      this.verifyItemsDueDate();
      const selectedRowsInitial = [...this.lineItemsToShow];
      this.totalBalance = selectedRowsInitial.reduce((sum, item) => sum + item.balance, 0);
      /* End initial assignment */
      const loadBillingsHistory = this.$store.getters["paymentHub/billings"];
      if (loadBillingsHistory.length > 0) {
        this.retryPayment(loadBillingsHistory);
      }
      this.loadingPaymentHub = false;
    },
    /* Sum in the payment hub values, to pay */
    updateSelectedValue() {
      this.selectedRows = this.lineItemsToShow.filter((item) => item.is_checked);
      this.totalSelectedRows = this.selectedRows.reduce((sum, item) => sum + item.balance, 0);
    },
    /* Retry Payment */
    retryPayment(loadsData) {
      const idsToShow = loadsData.map((billing) => billing.load_number);
      const pendingInvoicesToRetry = [];
      const notFoundIds = [];

      idsToShow.forEach((loadNumber) => {
        const foundItem = this.lineItemsToShow.find((item) => item.load_number === loadNumber);
        if (foundItem) {
          pendingInvoicesToRetry.push(foundItem);
        } else {
          let loadNumberToShow = loadNumber;
          if (loadNumberToShow === null) {
            loadNumberToShow = "SHPXXXX";
          }
          notFoundIds.push(loadNumberToShow);
        }
      });

      this.selectedRows = pendingInvoicesToRetry;
      if (notFoundIds.length > 0) {
        const loadsNumberList = notFoundIds.join(", ");
        this.swal({
          title: `The following loads number were not found to attempt payment: ${loadsNumberList}`,
          icon: "warning",
          dangerMode: false,
        });
      }
      this.checkRetryBilling();
    },

    /* Search Section */
    filterItems(data, search) {
      if (!search) return data;
      const filteredData = data.filter((item) => {
        const name = item.name ? item.name.toLowerCase() : "";
        const billingReference = item.billing_reference ? item.billing_reference.toLowerCase() : "";
        const loadNumber = item.load_number ? item.load_number.toLowerCase() : "";

        return (
          name.includes(search.toLowerCase()) ||
          billingReference.includes(search.toLowerCase()) ||
          loadNumber.includes(search.toLowerCase())
        );
      });
      return filteredData;
    },
    /* End Search Section */

    verifyItemsDueDate() {
      const currentDate = new Date();
      this.pastDueArray = this.lineItemsToShowCopy.filter((item) => {
        const dueDate = new Date(item.due_date);
        return currentDate > dueDate;
      });
      /* Sum total balance of overdue invoices */
      this.pastDueTotal = this.pastDueArray.reduce((sum, item) => sum + item.balance, 0);
    },

    /* Columns Sort */
    sortDates(a, b) {
      return new Date(a).getTime() - new Date(b).getTime();
    },
    sortNumbers(a, b) {
      return parseFloat(a) - parseFloat(b);
    },
    /* Load Info Button */
    goToLoadInfo(rowData) {
      if (rowData.load_id) {
        this.$router.push(`/my-loads/${rowData.load_id}`);
      } else {
        this.swal({
          title: "Can't see this load, Load Id not found",
          icon: "warning",
          dangerMode: false,
        });
      }
    },
    /* Payment */
    async goPayment() {
      const idsArray = this.selectedRows.map((item) => item.id);
      if (idsArray.length > 0) {
        const response = await this.$store.dispatch("paymentHub/goToPayment", idsArray);
        if (response !== undefined && response.url !== "") {
          window.open(response.url, "_blank");
          this.resetDefaultInfo();
          this.getTableData();
        }
      } else {
        this.swal({
          title: "Please select the invoices you want to pay!",
          icon: "warning",
          dangerMode: false,
        });
      }
    },

    /* Reset checkboxes and values then the paymaent melio is redirect */
    resetDefaultInfo() {
      this.selectedRows = [];
      this.totalSelectedRows = 0;
      this.lineItemsToShow.forEach((item) => {
        item.is_checked = false;
      });
    },

    /** SELECTION LOGIC */

    checkAllRef(value) {
      if (this.$refs.checkboxAll !== undefined) {
        this.$refs.checkboxAll.checked = value;
      }
    },

    paymentHubOption(option) {
      this.selectedOption = option;
      this.totalSelectedRows = 0;
      /* if (this.$refs.checkboxAll !== undefined) {
        this.$refs.checkboxAll.disabled = this.disabledCheckbox;
      } */
      switch (option) {
        case "total-balance":
          this.handleCheckAll();
          break;
        case "past-due":
          this.verifyItemsDueDate();
          this.handleCheckPastDue();
          break;
        default:
          this.checkAllRef(false);
          this.selectAll = false;
          this.clearCheck();
          break;
      }
    },

    async handleCheckAll(isHeaderCheckbox = false) {
      this.loadingPaymentHub = true;

      const updateItems = (checked) => {
        this.lineItemsToShow.forEach((item) => {
          item.is_checked = checked;
        });
      };

      if (isHeaderCheckbox && !this.selectAll) {
        updateItems(false);
        this.selectedRows = [];
        this.totalSelectedRows = 0;
        this.selectedOption = "selected-invoices";
      } else {
        updateItems(true);
        this.selectedRows = [...this.lineItemsToShow];
        this.totalBalance = this.selectedRows.reduce((sum, item) => sum + item.balance, 0);
        this.selectedOption = "total-balance";
        this.selectAll = true;
        this.checkAllRef(true);
      }
      this.loadingPaymentHub = false;
    },

    async handleCheckPastDue() {
      this.loadingPaymentHub = true;
      this.selectAll = false;
      this.selectedRows = [];

      const idsPastDue = new Set(this.pastDueArray.map((item) => item.id));

      this.lineItemsToShow.forEach((item) => {
        if (idsPastDue.has(item.id)) {
          item.is_checked = true;
          this.selectedRows.push(item);
        } else {
          item.is_checked = false;
        }
      });
      this.loadingPaymentHub = false;
    },

    clearCheck() {
      this.lineItemsToShow.forEach((item) => {
        item.is_checked = false;
      });
      this.selectedRows = [];
    },

    handleCheck(val, row) {
      if (val === true) {
        this.selectedRows.push(row);
      } else {
        this.selectedRows = this.selectedRows.filter((item) => item.id !== row.id);
        this.selectAll = false;
        this.checkAllRef(false);
      }

      if (this.selectedOption !== "selected-invoices") {
        this.selectedOption = "selected-invoices";
      }

      this.totalSelectedRows = this.selectedRows.reduce((sum, item) => sum + item.balance, 0);
    },

    checkRetryBilling() {
      const idsBilling = new Set(this.selectedRows.map((item) => item.id));

      this.lineItemsToShow.forEach((item) => {
        item.is_checked = idsBilling.has(item.id);
      });

      this.selectedOption = "selected-invoices";
      this.selectAll = false;
      this.checkAllRef(false);

      this.totalSelectedRows = this.selectedRows.reduce((sum, item) => sum + item.balance, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.payment-table-container {
  height: 85%;
}
.custom-divider {
  border-left: 2px solid #00a5cf;
  margin: 0 20px;
  height: auto;
}
.payment-hub-table {
  width: 60%;
  height: 100%;
}
.payment-hub {
  width: 30%;
  height: 100%;
  margin-left: 4%;
  margin-top: 14%;
  @media (max-width: 800px) {
    width: 100%;
    margin: 0;
  }
}
.payment-hub-container {
  display: flex;
  flex-direction: row;
  background-color: white;
  @include table-design(4);
  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
}
/* Icon Styles  */
.show-load-button {
  padding: 0px;
  background: none;
  border: none;
  font-size: 16px;
  color: $color-primary-company;
}

.show-load-icon {
  width: 16px;
}
/* Searcher */

.input-search {
  width: 37%;
  height: 36px;
  margin-bottom: 15px;
  margin-left: 60%;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 0px;
  }
}

::v-deep {
  .el-input__inner {
    border-radius: 15px;
    border: 2px solid #c0c4cc;
    letter-spacing: 0px;
    color: $color-primary-company;
    font-family: $font-family-portal;
    @include font-small-standart-text;
  }

  .el-input__suffix {
    color: $color-primary-company;
    font-size: 16px;
    margin-right: 10px;
  }
}
.check-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .custom-checkbox-box {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #dcdfe6;
    border-radius: 4px;
    background-color: #fff;
    transition: background-color 0.2s, border-color 0.2s;

    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  input[type="checkbox"]:checked + .custom-checkbox-box {
    background-color: #409eff;
    border-color: #409eff;

    &::after {
      display: block;
    }
  }

  input[type="checkbox"]:disabled + .custom-checkbox-box {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .custom-checkbox-box.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

::v-deep .el-input--mini .el-input__inner {
  border: none;
}

::v-deep {
  .el-table--scrollable-y .el-table__body-wrapper {
    padding-right: 10px;
  }
  .el-table,
  .el-table__expanded-cell {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: auto;
  }
  .el-table .cell {
    display: flex;
    justify-content: center;
    word-break: break-word;
  }

  .el-checkbox {
    margin-bottom: 0px;
  }
}
</style>
