<template>
  <div class="details">
    <!-- INFORMATION SECTION -->
    <div class="details__item">
      <span class="details__item--label">Payment number: </span>
      <span class="details__item--value">{{ selectedHistory.id }}</span>
    </div>
    <div class="details__item">
      <span class="details__item--label">Status: </span>
      <span :class="`status-${selectedHistory.status.toLowerCase().replaceAll(' ', '-')}`">
        {{ selectedHistory.status }}
      </span>
    </div>
    <div class="details__item">
      <span class="details__item--label">Total: </span>
      <span class="details__item--value">{{ formatCurrency(selectedHistory.amount)}} </span>
    </div>
    <!-- END INFORMATION SECTION -->
    <!-- LIST LOADS SECTION HORIZONTAL -->
    <span class="details__load-label">Load included in payment request:</span>
    <div class="details__load-container__horizontal" v-if="loadNumbers.length > 15">
      <div v-for="item, index in loadNumbers" :key="index" class="details__load-container__horizontal--load">
        {{  item  }}
      </div>
    </div>
    <!-- END LIST LOADS SECTION HORIZONTAL-->
    <!-- LIST LOADS SECTION VERTICAL -->
    <div class="details__load-container__vertical" v-else-if="loadNumbers.length <= 15 && loadNumbers.length > 0 ">
      <div v-for="item, index in loadNumbers" :key="index">
        {{  item  }}
      </div>
    </div>
    <!-- END LIST LOADS SECTION VERTICAL -->
    <div v-else>
      No data
    </div>
  </div>
</template>
<script>
export default {
  name: "HistorialPaymentDetails",
  props: {
    selectedHistory: {
      type: Object,
      required: true,
    }
  },

  computed: {
    loadNumbers() {
      const { billings } = this.selectedHistory;
      const loadNumbersMapping = billings.map((item) => item.load_number);
      return loadNumbersMapping.filter((item) => item !== null);
    }
  },

  methods: {
    formatCurrency(value) {
      return `$ ${parseFloat(value).toFixed(2)}`;
    },
  }
};
</script>
<style lang="scss" scope>
.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  margin-top: 50px;

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    &--label {
      font-weight: bold;
    }
    /* &--value {} */
  }

  &__load-label {
    font-weight: bold;
  }

  &__load-container{
    &__horizontal  {
      @extend %scroll-bar-styles;
      max-height: 450px;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      overflow-y: auto;
      &--load {
        width: 25%;
        text-align: left;
        @media (max-width: 1400px) {
          width: 30%;
        }
        @media (max-width: 1200px) {
          width: 40%;
        }
        @media (max-width: 800px) {
          width: 30%;
        }
      }
    }
    &__vertical {
      max-height: 450px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 5px;
      overflow-y: auto;
    }
  }
}

.status-pending {
  color: gray;
}

.status-scheduled {
  color: rgb(0, 204, 255);
}

.status-in-progress {
  color: rgb(21, 62, 213);
}

.status-paid {
  color: green;
}

.status-failed {
  color: red;
}

.status-created {
  color: gray;
}

.status-expired {
  color: black;
}
</style>
