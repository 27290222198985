<template>
  <div class="actions-container">
    <div class="actions-container__action">
      <el-button
        type="text"
        class="btn-retry-paid"
        @click.native.stop="retryPaid(payment)"
        :disabled="disableRetryPaid(payment)"
      >
        <b-icon v-b-popover.hover.top="'Retry Paid'" icon="arrow-repeat" scale="1.3" class="icon" />
      </el-button>
    </div>
    <div class="actions-container__action">
      <el-button
        type="text"
        class="btn-paid"
        @click.native.stop="paid(payment)"
        :disabled="disableGoToPaid(payment)"
      >
        <b-icon v-b-popover.hover.top="'Paid'" icon="link45deg" scale="1.3" class="icon" />
      </el-button>
    </div>
    <div class="actions-container__action">
      <el-button
        type="text"
        class="btn-download"
        @click.native.stop="downloadDocumentPayment()"
        :disabled="disableDownload(payment)"
      >
        <b-icon v-b-popover.hover.top="'Download'" icon="download" scale="1.3" class="icon" />
      </el-button>
    </div>
  </div>
</template>
<script>
import downloadDocument from "@/utils/DownloadDocuments";

export default {
  name: "historialPaymentActions",
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    disableDownload(payment) {
      return payment.document_path === null && payment.status !== "Paid";
    },

    disableGoToPaid(payment) {
      return payment.status === "Payment Expired" || payment.melio_url === "" || payment.melio_url === null;
    },

    disableRetryPaid(payment) {
      return !["Failed", "Payment Expired"].includes(payment.status);
    },

    paid(item) {
      this.$emit("paid", item);
    },

    retryPaid(item) {
      this.$emit("retryPaid", item);
    },

    async downloadDocumentPayment() {
      if (this.payment.document_path !== null) {
        const { document_path: documentPath } = this.payment;
        const url = await this.$store.dispatch("paymentHub/getPaymentFile", { path: documentPath });
        downloadDocument(url, `historial_payment_${this.payment.date}`);
      }
    },
  },
};
</script>
<style lang="scss" scope>
.actions-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 55%;
  margin: 0 auto;
  &__action {
    width: auto;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
}

.icon {
  color: $color-primary-company;
}

.btn-download {
  padding: 0px 5px;
}

.btn-paid {
  padding: 0px 5px;
}

.btn-retry-paid {
  padding: 0px 5px;
}

.btn-download.is-disabled {
  .icon {
    color: $color-disabled-button;
  }
}

.btn-paid.is-disabled {
  .icon {
    color: $color-disabled-button;
  }
}

.btn-retry-paid.is-disabled {
  .icon {
    color: $color-disabled-button;
  }
}
</style>
