<template>
  <div class="card" v-loading="loadingPayment">
    <div class="card-payment-hub">
      <div class="card-title">Payment</div>
      <div class="info-row">
        <input
          type="radio"
          name="invoice-options"
          id="past-due"
          value="past-due"
          v-model="selectedOption"
          @change="handleChange"
        />
        <label class="item" for="past-due">Past Due:</label>
        <span class="value">$ {{ pastDueTotal.toFixed(2) | insurancePrice }}</span>
      </div>
      <div class="info-row">
        <input
          type="radio"
          name="invoice-options"
          id="total-balance"
          value="total-balance"
          v-model="selectedOption"
          @change="handleChange"
        />
        <label class="item" for="total-balance">Total Balance:</label>
        <span class="value"> $ {{ totalBalance.toFixed(2) | insurancePrice }}</span>
      </div>
      <div class="info-row">
        <input
          type="radio"
          name="invoice-options"
          id="selected-invoices"
          value="selected-invoices"
          v-model="selectedOption"
          @change="handleChange"
        />
        <label class="item" for="selected-invoices">Selected Invoices:</label>
        <span class="value">$ {{ totalSelectedRows.toFixed(2) | insurancePrice }} </span>
      </div>
      <div class="payment-button-container">
        <button class="payment-button ml-3" @click="payOnlineButton">
          <span class="payment-button__text">
            Pay Online
            <i class="ion ion-card-sharp"></i>
          </span>
        </button>
        <div class="powered-by-container">
          <span class="powered-by-container__text">Powered by </span>
          <img class="icons-tools" src="@/assets/images/melio-logo-vector.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentHub",
  props: {
    pastDueTotal: {
      type: Number,
      default() {
        return 0;
      },
    },
    totalBalance: {
      type: Number,
      default() {
        return 0;
      },
    },
    totalSelectedRows: {
      type: Number,
      default() {
        return 0;
      },
    },
    loadingPayment: {
      type: Boolean,
      default() {
        return false;
      },
    },
    defaultOption: {
      type: String,
      default: "selected-invoices",
    },
  },

  data() {
    return {
      selectedOption: this.defaultOption,
    };
  },

  watch: {
    defaultOption(optionCheckbox) {
      this.selectedOption = optionCheckbox;
    },
  },

  methods: {
    payOnlineButton() {
      this.$emit("goPayment");
    },

    handleChange(e) {
      this.$emit("paymentHubOption", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.card-title {
  font-weight: bold;
  font-size: 21px;
}
.card-payment-hub {
  gap: 8px;
  border-radius: 10px !important;
  background-color: #ededed !important;
  box-shadow: 2px 2px 5px #8a8a8a;
  padding: 10px;
}

.info-row {
  display: flex;
}

.label {
  font-weight: bold;
  color: #2c3e50;
  flex-grow: 1;
}

.item {
  margin-left: 5%;
  margin-right: auto !important;
}

.value {
  font-weight: bold;
  color: #2c3e50;
}

.input[type="checkbox"] {
  accent-color: #00c0c7;
}
/* button */
.payment-button {
  width: 150px;
  border-radius: 15px;
  height: 40px;
  background: $color-primary-company;
  border: none;
  color: $color-white;
  &__text {
    font-size: 14px;
    font-weight: bold;
  }
  i {
    text-align: center;
  }
  @media (max-width: 500px) {
    margin-bottom: 5px;
  }
}

.payment-button-container {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  align-items: flex-end;
}
/* Powered By Melo */
.powered-by-container__text {
  font-size: 12px;
  font-family: "Roboto", sans-serif !important;
  width: 75px;
  color: gray;
}
.pay-online-button ::v-deep .el-loading-mask {
  border-radius: 13px;
}
.float-button ::v-deep .el-loading-mask {
  border-radius: 50px;
}
.background-color-pending {
  background-color: #04366370;
}
.powered-by-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  margin-right: 4px;
}
.icons-tools {
  width: 50px;
}
</style>
