<template>
  <div class="historial-container">
    <div v-if="screenWidth > 800">
      <HistorialPaymentTable @changeTab="(tabToChange) => changeTab(tabToChange)" />
    </div>
    <!-- Responsive Section -->
    <div v-else>
      <HistorialPaymentResponsive @changeTab="(tabToChange) => changeTab(tabToChange)" />
    </div>
    <!-- End Responsive Section -->
  </div>
</template>
<script>
import onReSize from "@/Extend/onResize";
import HistorialPaymentTable from "./HistorialPayment/HistorialPaymentTable.vue";
import HistorialPaymentResponsive from "./HistorialPayment/HistorialPaymentResponsive.vue";

export default {
  name: "HistorialPaymentIndex",
  props: {
    paymentTab: {
      type: String,
      default: "payment-history",
    },
  },

  mixins: [onReSize],
  components: {
    HistorialPaymentTable,
    HistorialPaymentResponsive,
  },

  async mounted() {
    this.resetHistory();
    await this.getPaymentHistory();
  },

  computed: {
    showResponsiveView() {
      return this.screenWidth <= 800;
    },
  },

  methods: {
    async getPaymentHistory(isFiltering = false) {
      await this.$store.dispatch("paymentHub/getPaymentHistory", isFiltering);
    },

    changeTab(tabToChange) {
      this.$emit("changeTab", tabToChange);
    },

    setHistoryParams(payload) {
      const { key, value } = payload;
      this.$store.commit("paymentHub/setHistoryParams", { key, value });
    },

    resetHistory() {
      this.$store.commit("paymentHub/setHistory", []);
      this.setHistoryParams({ key: "skip", value: 0 });
      this.setHistoryParams({ key: "search", value: null });
    },
  },
};
</script>
<style lang="scss" scope></style>
