<template>
  <div class="historial-payment-responsive">
    <SearchHistorialInput @handlerSearch="handlerSearch" />
    <div class="row-collapse">
      <div class="loading" v-show="loading">
        <b-icon icon="truck" animation="cylon" font-scale="4"></b-icon>
        <FadeAnimationTextArray :textArray="['Loading...']" />
      </div>
      <div ref="container" class="row-collapse__container accordion" role="tablist">
        <div
          v-for="(item, index) in history"
          :key="index"
          class="row-collapse__container--row"
          v-b-toggle="`collapse-information-${item.id}`"
        >
          <div class="card_container">
            <div class="card" role="tab">
              <div class="card__information">
                <div class="card__information--item">
                  <div class="item-label"># PAYMENT</div>
                  <div class="item-value">{{ item.id }}</div>
                </div>
                <div class="card__information--item">
                  <div class="item-label">STATUS</div>
                  <div
                    class="item-value"
                    :class="`status-${item.status.toLowerCase().replaceAll(' ', '-')}`"
                  >
                    {{ item.status }}
                  </div>
                </div>
                <div class="card__information--item">
                  <div class="item-label">PAYMENT DATE</div>
                  <div class="item-value">{{ item.date ? item.date : "Not Date" }}</div>
                </div>
              </div>
              <div class="card__total">
                <div class="item-label">TOTAL</div>
                <div class="item-value">{{ formatCurrency(item.amount) }}</div>
              </div>
              <div class="card__actions">
                <HistorialPaymentActions
                  :payment="item"
                  @paid="(e) => paid(e)"
                  @retryPaid="(e) => retryPaid(e)"
                />
              </div>
            </div>
            <div class="card_container__icon">
              <i class="ion ion-chevron-forward-outline"></i>
            </div>
          </div>
          <b-collapse
            class="collapse-body"
            :id="`collapse-information-${item.id}`"
            accordion="my-accordion"
            role="tabpanel"
            @show="item.collapsed = true"
            @hide="item.collapsed = false"
          >
            <HistorialPaymentDetails :selectedHistory="item" />
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import FadeAnimationTextArray from "@/components/FadeAnimationTextArray.vue";
import SearchHistorialInput from "./SearchHistorialInput.vue";
import HistorialPaymentActions from "./HistorialPaymentActions.vue";
import HistorialPaymentDetails from "./HistorialPaymentDetails.vue";

export default {
  name: "HistorialPaymentResponsive",
  data() {
    return {};
  },

  async mounted() {
    await this.sleep(0);
    this.handleScroll();
  },

  components: {
    SearchHistorialInput,
    HistorialPaymentActions,
    HistorialPaymentDetails,
    FadeAnimationTextArray,
  },

  computed: {
    ...mapGetters({
      history: "paymentHub/history",
      loading: "paymentHub/loading",
      dataLoaded: "paymentHub/dataLoaded",
      historyParams: "paymentHub/historyParams",
    }),
  },

  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    formatCurrency(value) {
      return `$ ${parseFloat(value).toFixed(2)}`;
    },

    async getPaymentHistory(isFiltering = false) {
      await this.$store.dispatch("paymentHub/getPaymentHistory", isFiltering);
    },

    setHistoryParams(payload) {
      const { key, value } = payload;
      this.$store.commit("paymentHub/setHistoryParams", { key, value });
    },

    handleScroll() {
      if (this.$refs.container !== undefined) {
        this.$refs.container.addEventListener("scroll", this.handleInfiniteScroll);
      }
    },

    async handleInfiniteScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      const isNearBottom = Math.ceil(scrollTop + clientHeight) + 1 >= scrollHeight;
      if (this.loading === false && !this.dataLoaded) {
        if (isNearBottom) {
          const { skip, limit } = this.historyParams;
          this.setHistoryParams({ key: "skip", value: skip + limit });
          await this.getPaymentHistory();
        }
      }
    },

    async handlerSearch(e) {
      this.setHistoryParams({ key: "search", value: e });
      this.setHistoryParams({ key: "skip", value: 0 });
      this.$store.commit("paymentHub/setDataLoaded", false);
      const search = e.trim();
      await this.getPaymentHistory(search !== "");
    },

    paid(payment) {
      window.open(payment.melio_url, "_blank");
    },

    retryPaid(payment) {
      const payload = payment.billings;
      this.$store.commit("paymentHub/setBillings", payload);
      this.$emit("changeTab", "pending-invoices");
    },

  },
};
</script>

<style lang="scss" scoped>
.historial-payment-responsive {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.row-collapse {
  position: relative;
  &__container {
    height: 72vh;
    padding: 2px;
    overflow: auto;
    @extend %scroll-bar-styles;
    &::-webkit-scrollbar {
      display: none;
    }
    &--row {
      @include collapse-container-styles;
    }
  }
}

.card_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & i {
    font-size: 20px;
  }
}

.card {
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: none;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  &__information {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 2px;
    gap: 2px;
    &--item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      .item-label {
        text-align: left;
        width: 50%;
      }
      .item-value {
        text-align: left;
        width: 50%;
      }
    }
  }
  &__total {
    width: 35%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    color: $text-primary;
  }
  &__actions {
    width: auto;
    padding: 0px 5px;
    @media (max-width: 800px) {
      padding-top: 10px;
    }
  }
}

// Styles for the collapsible container
.collapse-body {
  width: 100%;
  margin-top: 10px;
  border-top: 1px solid $color-border-container;
  // Deep styles for the `.details` component inside `.collapse-body`
  // `::v-deep` is used to apply styles to child components in Vue.js
  & ::v-deep .details {
    margin-top: 10px;
    &__load-container {
      &--load {
        @media (max-width: 1200px) {
          width: 30%;
        }
      }
    }
  }
}

.item-label {
  color: $color-primary-company;
  font-weight: bold;
}
.item-value {
  color: $text-primary;
}

.not-collapsed .card_container .card_container__icon {
  transform: rotate(90deg);
  transition: 0.4s all;
}

.collapsed .card_container .card_container__icon {
  transform: rotate(0deg);
  transition: 0.4s all;
}

.loading {
  @include loading-overlay;
  background-color: transparent !important;
  svg,
  p {
    color: $color-primary-company;
  }
}

.icon {
  color: $color-primary-company;
}

.status-pending {
  color: gray;
}

.status-scheduled {
  color: rgb(0, 204, 255);
}

.status-in-progress {
  color: rgb(21, 62, 213);
}

.status-paid {
  color: green;
}

.status-failed {
  color: red;
}

.status-created {
  color: gray;
}

.status-expired {
  color: black;
}
</style>
