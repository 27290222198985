<template>
  <el-input
    placeholder="Search..."
    suffix-icon="ion ion-search-outline"
    v-model="input"
    class="input-search"
    clearable
    @change="handlerSearch"
  />
</template>

<script>
export default {
  name: "SearchHistorialInput",
  data() {
    return {
      input: "",
    };
  },

  methods: {
    handlerSearch(query) {
      this.$emit("handlerSearch", query);
    },
  },
};

</script>

<style lang="scss" scoped>
.input-search {
  width: 37%;
  height: 36px;
  margin-bottom: 15px;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 0px;
  }
}

::v-deep {
  .el-input__inner {
    border-radius: 15px;
    border: 2px solid #c0c4cc;
    letter-spacing: 0px;
    color: $color-primary-company;
    font-family: $font-family-portal;
    @include font-small-standart-text;
  }

  .el-input__suffix {
    color: $color-primary-company;
    font-size: 16px;
    margin-right: 10px;
  }
}
</style>
