<template>
  <div>
    <div class="historial-payment" ref="agGridTableContainer">
      <div class="historial-payment__container">
        <!-- Search Section -->
        <div class="historial-payment__search">
          <SearchHistorialInput @handlerSearch="handlerSearch" />
        </div>
        <!-- End Search Section -->
        <div class="historial-payment__table">
          <el-table
            ref="tableHistory"
            :data="history"
            :height="tableHeight"
            :max-height="maxHeight"
            v-loading="loading"
            @row-click="rowClick"
          >
            <el-table-column
              v-for="(column, index) in columns"
              :key="index"
              :label="column.label"
              :prop="column.field"
              :sortable="column.sortable"
              :min-width="column.minWidth"
            >
              <template slot-scope="scope">
                <span v-if="column.field === 'amount'">
                  <span> $ {{ scope.row.amount | insurancePrice }}</span>
                </span>
                <span v-else-if="column.field === 'status'">
                  <span
                    :class="`status-${scope.row[column.field].toLowerCase().replaceAll(' ', '-')}`"
                  >
                    {{ scope.row[column.field] }}
                  </span>
                </span>
                <span v-else-if="column.field === 'tools'">
                  <HistorialPaymentActions
                    :payment="scope.row"
                    @paid="(e) => paid(e)"
                    @retryPaid="(e) => retryPaid(e)"
                  />
                </span>
                <span v-else>
                  {{ scope.row[column.field] }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-divider class="custom-divider" direction="vertical" />
      <!-- PDF Viewer Section -->
      <div class="historial-payment__details" :key="key">
        <el-empty
          v-if="showDocument === false && showDetails === false"
          :image-size="200"
          description="There are no available information at this moment."
        />
        <PdfViewer
          v-else-if="showDocument === true"
          v-loading="loadingFile"
          :selectedDocument="selectedDocument.url"
          :previewMode="false"
          :paymentDocument="selectedDocument"
          :isPaymentsDocument="true"
          currentType="historial_payment"
          @pdfViewerHidden="hideDocument()"
          @shareDocument="shareDocument"
          class="pdf-viewer"
        />
        <div v-else-if="showDetails === true">
          <HistorialPaymentDetails :selectedHistory="selectedHistory"/>
        </div>
      </div>
      <!-- End PDF Viewer Section -->
      <CustomModal
        :centered="true"
        :no-close-on-backdrop="true"
        size="md"
        modalTitle="Share document"
        v-model="shareDocumentModal"
      >
        <template #modal-content>
          <ShareDocument
            :documentInformation="documentToShare"
            :documentType="'invoice'"
            :isPaymentsDocument="true"
            :selectedPaymentDocument="selectedDocument"
            @closeShareDocumentModal="closeShareDocumentModal"
          />
        </template>
      </CustomModal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShareDocument from "@/components/ShareDocument.vue";
import CustomModal from "@/components/CustomModal.vue";
import "@/assets/scss/components/agGridStyleOverride.scss";
import PdfViewer from "@/components/PdfViewer.vue";
import SearchHistorialInput from "./SearchHistorialInput.vue";
import HistorialPaymentActions from "./HistorialPaymentActions.vue";
import HistorialPaymentDetails from "./HistorialPaymentDetails.vue";

export default {
  name: "historialPaymentTable",
  components: {
    HistorialPaymentActions,
    SearchHistorialInput,
    HistorialPaymentDetails,
    PdfViewer,
    ShareDocument,
    CustomModal,
  },
  data() {
    return {
      tableHeight: "600px",
      maxHeight: "600px",
      columns: [
        {
          label: "# PAYMENT",
          field: "id",
          sortable: true,
          sortMethod: this.sortNumbers,
          minWidth: 115,
        },
        {
          label: "STATUS",
          field: "status",
          sortable: true,
          minWidth: 100,
        },
        {
          label: "PAYMENT DATE",
          field: "date",
          sortable: true,
          sortMethod: this.sortDates,
          minWidth: 130,
        },
        {
          label: "PAYMENT TOTAL",
          field: "amount",
          sortable: true,
          sortMethod: this.sortNumbers,
          minWidth: 150,
        },
        {
          label: "TOOLS",
          field: "tools",
          minWidth: 145,
        },
      ],
      selectedDocument: {
        name: null,
        url: null
      },
      selectedHistory: null,
      showDocument: false,
      showDetails: false,
      /* Scroll Infinite */
      tableContainer: null,
      tableContainerHeight: null,
      rowsContainer: null,
      shareDocumentModal: false,
      documentToShare: null,
      key: 0
    };
  },

  async mounted() {
    await this.sleep(0);
    this.handleScroll();
  },

  computed: {
    ...mapGetters({
      billings: "paymentHub/billings",
      history: "paymentHub/history",
      historyParams: "paymentHub/historyParams",
      loading: "paymentHub/loading",
      loadingFile: "paymentHub/loadingFile",
      dataLoaded: "paymentHub/dataLoaded",
    }),
  },

  methods: {
    sleep(timeToSleep) {
      return new Promise((resolve) => setTimeout(resolve, timeToSleep));
    },

    sortDates(startDate, endDate) {
      return new Date(startDate).getTime() - new Date(endDate).getTime();
    },

    sortNumbers(startNumber, endNumber) {
      return parseFloat(startNumber) - parseFloat(endNumber);
    },

    async getPaymentHistory(isFiltering = false) {
      await this.$store.dispatch("paymentHub/getPaymentHistory", isFiltering);
    },

    setHistoryParams(payload) {
      const { key, value } = payload;
      this.$store.commit("paymentHub/setHistoryParams", { key, value });
    },

    handleScroll() {
      this.tableContainer = this.$refs.tableHistory.$el.querySelector(
        ".el-table__body-wrapper"
      );
      this.tableContainerHeight = this.tableContainer.clientHeight;
      this.rowsContainer = this.$refs.tableHistory.$el.querySelector(".el-table__body");
      this.tableContainer.addEventListener("scroll", this.handleInfiniteScroll);
    },

    async handleInfiniteScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      const isNearBottom = Math.ceil(scrollTop + clientHeight) + 1 >= scrollHeight;

      if (this.loading === false && !this.dataLoaded) {
        if (isNearBottom) {
          const { skip, limit } = this.historyParams;
          this.setHistoryParams({ key: "skip", value: skip + limit });
          await this.getPaymentHistory();
        }
      }
    },

    async handlerSearch(valueSearched) {
      this.setHistoryParams({ key: "search", value: valueSearched });
      this.setHistoryParams({ key: "skip", value: 0 });
      this.$store.commit("paymentHub/setDataLoaded", false);
      const search = valueSearched.trim();
      await this.getPaymentHistory(search !== "");
    },

    async rowClick(row) {
      const { id, document_path: documentPath, status } = row;
      this.showDetails = false;
      this.showDocument = false;
      this.selectedDocument = null;
      this.selectedHistory = null;

      if (row.document_path !== null && status.toLowerCase() === "paid") {
        this.selectedDocument = {
          name: id,
          documentPath
        };
        const url = await this.$store.dispatch("paymentHub/getPaymentFile", { path: documentPath });
        this.selectedDocument.url = url;
        this.showDocument = true;
        this.key += 1;
      } else {
        this.showDetails = true;
        this.selectedHistory = row;
      }
    },

    hideDocument() {
      this.selectedDocument = {
        name: null,
        url: null
      };
    },

    paid(payment) {
      window.open(payment.melio_url, "_blank");
    },

    retryPaid(payment) {
      const payload = payment.billings;
      /* this.$store.dispatch("paymentHub/setBillings", payload); */
      this.$store.commit("paymentHub/setBillings", payload);
      this.$emit("changeTab", "pending-invoices");
    },

    shareDocument(document) {
      this.shareDocumentModal = true;
      this.documentToShare = document;
    },

    closeShareDocumentModal() {
      this.shareDocumentModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-divider {
  border-left: 2px solid #00a5cf;
  margin: 0 15px;
  height: auto;
}

.pdf-viewer ::v-deep .back-icon {
  color: $color-primary-company-lighten;
  top: 10px;
}

.historial-payment {
  height: auto;
  display: flex;
  flex-direction: row;
  @include table-design(2);

  &__container {
    width: 60%;
    height: auto;
  }

  &__search {
    width: 100%;
    margin: auto 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  &__table {
    width: 100%;
    &--payment-total {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 20px;
    }
  }

  &__details {
    width: 40%;
    height: 100%;
  }
}

.pdf-document {
  width: 100%;
}

.status-pending {
  color: gray;
}

.status-scheduled {
  color: rgb(0, 204, 255);
}

.status-in-progress {
  color: rgb(21, 62, 213);
}

.status-paid {
  color: green;
}

.status-failed {
  color: red;
}

.status-created {
  color: gray;
}

.status-expired {
  color: black;
}

::v-deep {
  .el-table {
    &__cell {
      text-align: center;
    }
  }

  .el-table,
  .el-table__expanded-cell {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}
</style>
